import React, { useState } from 'react'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


const Container = styled.div`
    padding: 60px 10%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Image = styled.div`
    background-repeat: no-repeat;
    background-image: ${props => `url(${props.img})`};
    background-position: 0cm;
    background-size: 100%;
    width: 60%;
    min-height: 30vw;
    margin: 0 auto;
`

const Caption = styled.p`
    font-size: 12px;
`

export default function ImageDisplay({img, caption}) {

    const [open, setOpen] = useState(false);

    return (
        <Container>
            <Image img={img} onClick={ () => setOpen(true) }/>
            {open && <Lightbox 
                mainSrc={img}
                onCloseRequest={() => setOpen(false)}
                toolbarButtons={[]}
            />}
            <Caption>{caption}</Caption>
        </Container>
    )
}
