import React, { useContext } from 'react'
import Layout from '../../../components/layout'
import DotDivider from '../../../components/layout/DotDivider';
import Footer from '../../../components/layout/Footer';
import HeroContainer from '../../../components/layout/HeroContainer'
import ImageDisplay from '../../../components/layout/ImageDisplay';
import Offer from '../../../components/layout/Offer';
import ParagraphWithTitle from '../../../components/layout/ParagraphWithTitle';
import SimilarOffers from '../../../components/layout/SimilarOffers';
import SmallParagraphFeature from '../../../components/layout/SmallParagraphFeature';
import VerticalList from '../../../components/layout/VerticalList';
import SEO from '../../../components/seo';
import { AppContext } from '../../../contexts/AppContext';
import graph from '../../../images/how/circular-economy/graph.png'

export default function CircularEconomy() {

    const { appState } = useContext(AppContext);

    return (
        <Layout>
            <SEO title={appState.i18n.circularEconomy.title} />
            <HeroContainer  title={appState.i18n.circularEconomy.title} subtitle={appState.i18n.circularEconomy.text} id="circular-economy" />
            <DotDivider />
            <ImageDisplay img={graph} caption={appState.i18n.all.expand} />
            <DotDivider />
            <ParagraphWithTitle title={appState.i18n.circularEconomy.paragraphTitle} texts={[appState.i18n.circularEconomy.paragraphText1, appState.i18n.circularEconomy.paragraphText2, appState.i18n.circularEconomy.paragraphText3]}/>
            <DotDivider />
            <SimilarOffers title={appState.i18n.circularEconomy.similarOffersTitle}>
                <Offer link={"/solutions/private/powerpack"} title={appState.i18n.similarOffers.powerpacksTitle} text={appState.i18n.similarOffers.powerpacksText} />
                {/* <Offer link={"/solutions/private/powerbox"} title={appState.i18n.similarOffers.powerboxesTitle} text={appState.i18n.similarOffers.powerboxesText} /> */}
            </SimilarOffers>
            <Footer />
        </Layout>

    )
}
